export default (config = {}) => ({

    // Data variables
    spotPriceData: [],
    spotPriceDataUsdt: [],
    spotPriceData365: [],
    spotPriceDataUsdt365: [],
    currentDifficultyData: [],
    vwapData: [],
    cumulativeDifficultyData: [],
    movingAverageData: [],
    movingAverageDataMwc:[],
    movingAverageDifficulty:[],
    MwcStartTime: null,
    MwcEndTime: new Date().toISOString(),

    // Chart instances
    spotPriceChart: null,
    difficultyChart: null,
    vwapCumulativeChart: null,
    yearlyVwapCumulativeChart: null,
    movingAverageChart: null,
    selectedTimeframe: '2h',


    // Initialization method
    init() {
        this.fetchData();
        this.fetchAndDrawCharts(this.selectedTimeframe); // Default view
        this.selectTimeframe(this.selectedTimeframe)
    },

    selectTimeframe(timeframe) {
        this.selectedTimeframe = timeframe;
        this.fetchAndDrawCharts(timeframe);
    },

    async fetchData() {
        try {
            // Fetch spot price data
            const spotPriceResponse365 = await axios.get("https://mwc2.pacificpool.ws/api/price-indexes/get-mwc-btc/",{
                params: {period:"1y" }
            });
            this.spotPriceData365 = this.formatDataForChart(spotPriceResponse365.data, "Spot Price one year");

            const spotPriceResponseUsdt365 = await axios.get("https://mwc2.pacificpool.ws/api/price-indexes/get-mwc-usd/",{
                params: {period:"1y" }
            });
            this.spotPriceDataUsdt365 = this.formatDataForChart(spotPriceResponseUsdt365.data, "Current Difficulty");

            const response = await axios.get("https://mwc2.pacificpool.ws/api/price-indexes-background/fetch-difficulty-data/");
            this.currentDifficultyData = this.formatDataForChart(response.data,"Current Difficulty");
            
            // Fetch 200-day Moving Average data
            const movingAverageResponse = await axios.get("https://mwc2.pacificpool.ws/api/price-indexes/get-mwc-usd-ma/");
            // this.movingAverageData = this.formatDataForChart(movingAverageResponse.data, "Current Difficulty");
            const rawData = movingAverageResponse.data;
             // Log raw data to verify
            console.log("Raw Data:", rawData);
            // Filter data to only include entries from the past year
            this.movingAverageData = this.filterDataForOneYear(rawData);
            // Format the data for the chart
           this.movingAverageData = this.formatDataForChart(this.movingAverageData, "Moving Average");


            // Fetch 200-day Moving Average data
            const movingAverageResponseMwc = await axios.get("https://mwc2.pacificpool.ws/api/price-indexes/get-mwc-btc-ma/");
            const rawDataMWC = movingAverageResponseMwc.data;
            this.movingAverageDataMwc = this.filterDataForOneYear(rawDataMWC);
            this.movingAverageDataMwc = this.formatDataForChart(this.movingAverageDataMwc, "Moving Average");



            const movingAverageDifficulty = await axios.get("https://mwc2.pacificpool.ws/api/price-indexes-background/moving-averages/");
            this.movingAverageDifficulty = this.formatDataForChart(movingAverageDifficulty.data, "Current Difficulty");


            // Draw charts
            this.drawMovingAverageChart();
            this.drawCurrentDifficultyChart();
            this.drawMovingAverageChartMwc();
            this.drawUSDTSpotPriceChart365();
            this.drawSpotPriceChart365();
            this.fetchMovingAverageData();
            this.drawDifficultyChart();

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    },
    formatWithCommas(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDataForChart(data, type) {
        if (type === "200-day Moving Average MWC-BTC") {
            return Object.entries(data).map(([date, price]) => {
                const value = parseFloat(price);
                console.log("mwc moving_average_200 value Data:", value);
                return {
                    x: new Date(date),
                    y: !isNaN(value) ? parseFloat(value.toFixed(8)) : null
                };
            });
        }
        return Object.entries(data).map(([date, price]) => ({
            x: new Date(date),
            y: price
        }));
    },

    filterDataForOneYear(data) {
        // Convert object to array of { x: date, y: value }
        const dataArray = Object.entries(data).map(([date, value]) => ({
            x: new Date(date),  // Convert date string to Date object for comparison
            y: parseFloat(value)  // Ensure the value is a number
        }));
    
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    
        // Filter the data to only include entries from the last year
        const filteredArray = dataArray.filter(item => item.x >= oneYearAgo);
    
        // Convert filtered array back to an object
        const filteredObject = filteredArray.reduce((acc, item) => {
            const dateKey = item.x.toISOString().split('T')[0];  // Convert Date object back to date string (YYYY-MM-DD)
            acc[dateKey] = item.y.toString();  // Convert the value back to string (if needed)
            return acc;
        }, {});
    
        return filteredObject;
    }
    ,

    

    drawSpotPriceChart365() {
        console.log({spotPriceData365:this.spotPriceData365});
        const options = {
            chart: {
                type: 'line',
                height: 250,
                width: '100%',
                foreColor: '#9606E4'
            },
            series: [
                {
                    name: 'Spot Price one year',
                    data: this.spotPriceData365,
                    color: '#9606E4'  // Data formatted with x (timestamp) and y (difficulty)
                },
            ],
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: 'Spot Price one year',
                },
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                },
            }
        };
    
        this.spotPriceChart365 = new ApexCharts(this.$refs.spotPriceChart365, options);
        this.spotPriceChart365.render();
    },

    drawUSDTSpotPriceChart365() {
        const options = {
            chart: {
                type: 'line',
                height: 250,
                width: '100%',
                foreColor: '#9606E4'
            },
            series: [
                {
                    name: 'Spot Price MWC-USDT one year',
                    data: this.spotPriceDataUsdt365,
                    color: '#9606E4'  // Data formatted with x (timestamp) and y (difficulty)
                },
            ],
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: 'Spot Price MWC-USDT one year',
                },
                labels: {
                    formatter: function (value) {
                        return parseInt(value); // Remove decimals by converting to an integer
                    }
                }
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                },
            }
        };
    
        this.spotPriceChartUSDT365 = new ApexCharts(this.$refs.spotPriceChartUSDT365, options);
        this.spotPriceChartUSDT365.render();
    },

    drawCurrentDifficultyChart() {
        const options = {
            chart: {
                type: 'line',
                height: 250,
                width: '100%',
                foreColor: '#9606E4'
            },
            series: [
                {
                    name: 'Current Difficulty',
                    data: this.currentDifficultyData,  // Data formatted with x (timestamp) and y (difficulty)
                    color: '#9606E4'
                },
            ],
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: 'Difficulty',
                },
                labels: {
                    formatter: function (value) {
                        return value.toLocaleString(); // Format large numbers with commas
                    }
                }
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                },
            }
        };
    
        this.CurrentdifficultyChart = new ApexCharts(this.$refs.CurrentdifficultyChart, options);
        this.CurrentdifficultyChart.render();
    }
    ,
    drawMovingAverageChart() {
        console.log({Movingavaerage:this.movingAverageData});
        const length = Object.keys(this.movingAverageData).length;
        console.log("leght of two datd",length); // Output: 3
        const options = {
            chart: {
                type: 'line',
                        height: 250,
                        width: '100%',
                        foreColor: '#9606E4'
            },
            series: [
                {
                    name: "200-day Moving Average",
                            data: this.movingAverageData,
                            color: '#9606E4' // Data formatted with x (timestamp) and y (difficulty)
                },
            ],
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: '200-day Moving Average',
                },
                labels: {
                    formatter: function (value) {
                        return parseInt(value); // Remove decimals by converting to an integer
                    }
                }
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                },
            }
        };
    
        this.movingAverageChart = new ApexCharts(this.$refs.movingAverageChart, options);
        this.movingAverageChart.render();
    }
    ,
    drawMovingAverageChartMwc() {
        console.log({MovingavaerageMWC:this.movingAverageDataMwc});
        const options = {
            chart: {
                type: 'line',
                height: 250,
                width: '100%',
                foreColor: '#9606E4'
            },
            series: [
                {
                    name: "200-day Moving Average MWC-BTC",
                    data: this.movingAverageDataMwc,
                    color: '#9606E4'// Data formatted with x (timestamp) and y (difficulty)
                },
            ],
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: '200-day Moving Average MWC-BTC',
                },
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                },
            }
        };
    
        this.movingAverageChartMwc = new ApexCharts(this.$refs.movingAverageChartMwc, options);
        this.movingAverageChartMwc.render();
    }
    ,
    fetchMovingAverageData() {
        const options = {
            chart: {
                type: 'line',
                height: 250,
                width: '100%',
                foreColor: '#9606E4'
            },
            series: [
                {
                    name: "200-day Moving Average Difficulty",
                    data: this.movingAverageDifficulty,
                    color: '#9606E4'// Data formatted with x (timestamp) and y (difficulty)
                },
            ],
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: '200-day Moving Average Difficulty',
                },
                labels: {
                    formatter: function (value) {
                        return value.toLocaleString(); // Format large numbers with commas
                    }
                }
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                },
            }
        };
    
        this.movingAverageDifficulty = new ApexCharts(this.$refs.movingAverageDifficulty, options);
        this.movingAverageDifficulty.render();
    },
    
    async fetchAndDrawCharts(timeframe) {
        // Fetch data for VWAP chart
        const vwapData = await axios.get("https://mwc2.pacificpool.ws/api/price-indexes/test_vwap_mwc_interval", {
            params: { interval: timeframe }
        });
        this.vwapData = this.formatDataForChart(vwapData.data, "200-day Moving Average MWC-BTC");
    
        // Fetch data for Difficulty chart
        const cumulativeDifficulty = await axios.get("https://mwc2.pacificpool.ws/api/price-indexes-background/cumulative_difficulty_data_interval", {
            params: { interval: timeframe }
        });
        this.cumulativeDifficultyData = this.formatDataForChart(cumulativeDifficulty.data, "Current Difficulty");
    
        // Draw both charts
        this.drawVWAPChart(timeframe);
        this.drawDifficultyChart(timeframe);
    },
    
    drawVWAPChart() {
        const options = {
            chart: {
                type: 'line',
                height: 350,
                width: '100%',
                foreColor: '#9606E4'
            },
            series: [
                {
                    name: "200-day Moving Average MWC-BTC",
                    data: this.vwapData, // Data formatted with x (timestamp) and y (price)
                    color: '#9606E4'
                }
            ],
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: '200-day Moving Average MWC-BTC'
                }
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            }
        };
    
        if (this.vwapChart) {
            this.vwapChart.updateOptions(options);
        } else {
            this.vwapChart = new ApexCharts(this.$refs.vwapChart, options);
            this.vwapChart.render();
        }
    },
    
    drawDifficultyChart() {
        const options = {
            chart: {
                type: 'line',
                height: 350,
                width: '100%',
                foreColor: '#9606E4'
            },
            series: [
                {
                    name: "Current Difficulty",
                    data: this.cumulativeDifficultyData, // Data formatted with x (timestamp) and y (difficulty)
                    color: '#E44B06'
                }
            ],
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: 'Current Difficulty'
                },
                labels: {
                    formatter: function (value) {
                        return value.toLocaleString(); // Format large numbers with commas
                    }
                }
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            }
        };
    
        if (this.difficultyChart) {
            this.difficultyChart.updateOptions(options);
        } else {
            this.difficultyChart = new ApexCharts(this.$refs.difficultyChart, options);
            this.difficultyChart.render();
        }
    }
    ,

    formatWithCommas(value) {
        if (isNaN(value)) {
            return value;
        }
        return parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 });
    },

    formatWithCommasDifficulty(value) {
        if (isNaN(value)) {
            return value;
        }
        // Format the number with commas and up to 8 decimal places
        let formattedValue = parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 });
        
        // Remove trailing zeros and the trailing decimal point if necessary
        formattedValue = formattedValue.replace(/(\.0+|(?<=\.\d*)0+)$/, '');
        
        return formattedValue;
    },
    

    removeTrailingZeros(numberStr) {
        if (typeof numberStr !== 'string') {
          numberStr = numberStr.toString();
        }
        
        if (!numberStr.includes('.')) {
          return numberStr;
        }
        
        // Remove trailing zeros
        numberStr = numberStr.replace(/0+$/, '');
        
        // Remove trailing decimal point if there are no digits following it
        if (numberStr.endsWith('.')) {
          numberStr = numberStr.slice(0, -1);
        }
        
        return numberStr;
      },
	

    formatString(input) {
        let trimmedInput = String(input).trim();
        if (!isNaN(trimmedInput) && trimmedInput == "") {
            let number = parseFloat(trimmedInput);
            return new Intl.NumberFormat().format(number);
        } else {
            return trimmedInput;
        }
    },

    formatToEightDecimalPlaces(input, precision) {
        if (input === null) return null;

        let trimmedInput = String(input).trim();

        if (!isNaN(trimmedInput) && trimmedInput == "") {
            let number = parseFloat(trimmedInput);
            return number.toFixed(precision);
        } else {
            return trimmedInput;
        }
    }
})
  

